import React from "react";
import Layout from "components/layout/layout-feature";
import SEO from "components/seo";
import { SignupButton, Link, Row, Typography, Container, Section, SectionTitle, FeatureRows, Feature } from "components/ui";
import Image from "components/image"
import { BackupTypes } from "../../components/sections";
import myGif from '../../images/app/aws-s3-glacier-storage-flag.gif'

const DatabaseBackup = () => (
  <Layout isDark={true} withBackground={true}>
		<SEO refKey="aws-s3-glacier-storage" />

    <div className="bg-aubergine text-white py-20">
      <Container className="">
        <div className="text-center">
          <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">
            Store your backups on AWS Glacier
          </h1>
          <Typography tag="p">Long-term, secure, durable Amazon S3 object storage classes for your backups</Typography>
          <div className="flex items-center justify-center font-bold mt-6">Fully automated with
            <Image filename="aws-glacier-ico.png" alt="aws glacier backup" className="ml-3 w-12"/></div>
          <SignupButton className="mt-6" text="Secure your backups on Glacier" urlAtrr={{ sb_source: "website", sb_term: "aws-glacier" }} />
        </div>
      </Container>
    </div>

    <Section>
      <Container>
        <Row>
          <FeatureRows items={
            [
              {
                title: "Bring your own AWS S3 bucket",
                description: (
                  <>
                    <Typography tag="p" className="mt-2 mb-6">
                      Store your backups on your own <strong>AWS S3</strong> bucket.<br/>
                      Configure your backups, your retention policy and store as many backups as you need.
                    </Typography>
                    <Link href="/features/" arrow="right">See all features</Link>
                  </>
                ),
                image: (<Image
                  data-aos="fade-left"
                  filename="simplebackups-aws-s3-storage.png"
                  alt="aws backups"
                  style={{maxWidth: 757}}
                />)
              },

              {
                title: "Turn your storage into Glacier",
                description: (
                  <>
                    <Typography tag="p" className="mt-2 mb-6">
                      Turn your storage into a Glacier storage in one click.
                      Your backup will now automatically be stored into AWS Glacier.
                    </Typography>
                  </>
                ),
                image: (<img src={myGif} alt="aws glacier backups" style={{maxWidth: 600}} className="rounded-xl shadow-2xl"/>)
              }

            ]}
          />
        </Row>
      </Container>
    </Section>

    <Section>
      <Container>
        <Row>
          <SectionTitle
            title={(<>Backup your servers and databases</>)}
            tag="h2"
            align="center"
          />

          <BackupTypes/>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-20 mt-20">
            <Feature
              icon={<i className="fa fa-glass" />}
              title="Select your bucket"
              content="Stop wasting time with different backup solutions for each customer, have all your backups available in one place, no matter where your website is hosted."
              boxed
            />
            <Feature
              icon={<i className="fa fa-balance-scale" />}
              title="Stay GDPR compliant"
              content="Host your backups in the region you need to, to remain compliant with GDPR policies."

              boxed
            />
            <Feature
              icon={<i className="fa fa-infinity" />}
              title="Unlimited backup storage"
              content="Take advantage of AWS infrastructure and store as many backups as you want."
              boxed
            />
          </div>
        </Row>
      </Container>
    </Section>

  </Layout>
)

export const frontmatter = {
  pageMetaData: {
    refKey: "aws-s3-glacier-storage",
    title: "AWS S3 Glacier backup storage",
    description: "AWS S3 Glacier backup and restore files and databases, easy to set and forget, supports all AWS S3 regions and connection methods.",
    author: "simplebackups.com"
  }
}

export default DatabaseBackup
